import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CheckoutSettingsApi} from '../apis/CheckoutSettingsApi';
import {CheckoutSettingsModel} from '../models/checkoutSettings/CheckoutSettings.model';
import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {CheckoutOrigin} from '../utils/bi.util';
import {SPECS} from '../../components/Checkout/constants';

export class CheckoutSettingsService {
  private readonly controllerConfig: IWidgetControllerConfig;
  private readonly siteStore: SiteStore;
  private readonly checkoutSettingsApi: CheckoutSettingsApi;
  public checkoutSettings!: CheckoutSettingsModel;
  public showCouponSP!: boolean;

  constructor({controllerConfig, siteStore}: {controllerConfig: IWidgetControllerConfig; siteStore: SiteStore}) {
    this.controllerConfig = controllerConfig;
    this.siteStore = siteStore;
    this.checkoutSettingsApi = new CheckoutSettingsApi({siteStore, origin: CheckoutOrigin.checkout});
  }

  public async fetchSiteSettings(): Promise<void> {
    const useNewQueryCheckoutSettings = this.siteStore.experiments.enabled(SPECS.UseNewQueryCheckoutSettings);
    const checkoutSettingsAndPayments = useNewQueryCheckoutSettings
      ? await this.checkoutSettingsApi.getCheckoutSettings()
      : undefined;
    const checkoutSettingsOld = useNewQueryCheckoutSettings
      ? undefined
      : await this.checkoutSettingsApi.getCheckoutSettingsOld();
    this.checkoutSettings = new CheckoutSettingsModel({
      checkoutSettingsAndPayments,
      checkoutSettingsOld,
      taxName: this.siteStore.taxName,
      siteStore: this.siteStore,
    });
  }

  public async init(): Promise<void> {
    await this.fetchSiteSettings();
    this.showCouponSP = this.controllerConfig.config.style.styleParams.booleans.checkout_showCoupon !== false;
  }
}
