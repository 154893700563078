import {ShowMandatoryTitleModel} from './ShowMandatoryTitle.model';
import {ValueEnabledModel} from './ValueEnabled.model';
import {ValueEnabledTitleModel} from './ValueEnabledTitle.model';
import {ShowMandatoryModel} from './ShowMandatory.model';
import {StoreCheckoutSettingsAndPayments} from '../../utils/types.util';
import {PaymentMethod, StoreCheckoutSettings} from '@wix/wixstores-graphql-schema-node';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {SPECS} from '../../../components/Checkout/constants';

export class CheckoutSettingsModel {
  public storeName?: string;
  public logoUrl?: string;
  public taxName: string;
  public taxOnProduct: boolean;
  public customField: ShowMandatoryTitleModel;
  public addressLine2: ShowMandatoryModel;
  public companyName: ShowMandatoryModel;
  public phone: ShowMandatoryModel;
  public termsAndConditions: ValueEnabledModel;
  public privacyPolicy: ValueEnabledModel;
  public returnPolicy: ValueEnabledModel;
  public digitalPolicy: ValueEnabledModel;
  public contactUs: ValueEnabledTitleModel;
  public customPolicy: ValueEnabledTitleModel;
  public arePoliciesCheckedByDefault: boolean;
  public areGeneralPoliciesEnabled: boolean;
  public isThereAnyPolicyEnabled: boolean;
  public isDigitalPolicyEnabled: boolean;
  public isSubscriptionCheckedByDefault: boolean;
  public isSubscriptionEnabled: boolean;
  public isGiftCardEnabled: boolean;
  public isGiftCardSupported: boolean;
  public paymentProviders?: string;
  public activePaymentMethods?: PaymentMethod[];

  constructor({
    checkoutSettingsAndPayments,
    checkoutSettingsOld,
    taxName,
    siteStore,
  }: {
    checkoutSettingsAndPayments?: StoreCheckoutSettingsAndPayments;
    checkoutSettingsOld?: StoreCheckoutSettings;
    taxName: string;
    siteStore: SiteStore;
  }) {
    if (siteStore.experiments.enabled(SPECS.UseNewQueryCheckoutSettings)) {
      const _checkoutSettings = checkoutSettingsAndPayments!.checkoutSettings;
      this.storeName = _checkoutSettings.storeName ?? undefined;
      this.logoUrl = _checkoutSettings.logo?.url;
      this.taxName = taxName;
      this.taxOnProduct = _checkoutSettings.taxOnProduct ?? false;
      this.customField = new ShowMandatoryTitleModel(_checkoutSettings.checkoutCustomField);
      this.addressLine2 = new ShowMandatoryModel(_checkoutSettings.checkoutAddressLine2);
      this.companyName = new ShowMandatoryModel(_checkoutSettings.checkoutCompanyName);
      this.phone = new ShowMandatoryModel(_checkoutSettings.checkoutPhone);
      this.termsAndConditions = new ValueEnabledModel(_checkoutSettings.termsAndConditions);
      this.privacyPolicy = new ValueEnabledModel(_checkoutSettings.privacyPolicy);
      this.returnPolicy = new ValueEnabledModel(_checkoutSettings.refundCancellationPolicy);
      this.digitalPolicy = new ValueEnabledModel(_checkoutSettings.digitalItemPolicy);
      this.contactUs = new ValueEnabledTitleModel(_checkoutSettings.contactUs);
      this.customPolicy = new ValueEnabledTitleModel(_checkoutSettings.customPolicy);
      this.arePoliciesCheckedByDefault = !!_checkoutSettings.checkoutPolicyCheckbox?.checked;
      this.isThereAnyPolicyEnabled =
        this.termsAndConditions.enabled || this.privacyPolicy.enabled || this.returnPolicy.enabled;
      this.areGeneralPoliciesEnabled = !!_checkoutSettings.checkoutPolicyCheckbox?.show && this.isThereAnyPolicyEnabled;
      this.isDigitalPolicyEnabled = !!_checkoutSettings.checkoutPolicyCheckbox?.show && this.digitalPolicy.enabled;
      this.isSubscriptionCheckedByDefault = !!_checkoutSettings.checkoutSubscriptionCheckbox?.checked;
      this.isSubscriptionEnabled = !!_checkoutSettings.checkoutSubscriptionCheckbox?.show;
      this.isGiftCardEnabled = !!_checkoutSettings.checkoutGiftCardCheckbox?.show;
      this.isGiftCardSupported = !!_checkoutSettings.checkoutGiftCardCheckbox?.supported;
      this.activePaymentMethods = checkoutSettingsAndPayments!.payments.activePaymentMethods;
      this.paymentProviders = this.activePaymentMethods.map((paymentMethod) => paymentMethod.name).join(',');
    } else {
      const _checkoutSettings = checkoutSettingsOld!;
      this.storeName = _checkoutSettings.storeName ?? undefined;
      this.logoUrl = _checkoutSettings.logo?.url;
      this.taxName = taxName;
      this.taxOnProduct = _checkoutSettings.taxOnProduct ?? false;
      this.customField = new ShowMandatoryTitleModel(_checkoutSettings.checkoutCustomField);
      this.addressLine2 = new ShowMandatoryModel(_checkoutSettings.checkoutAddressLine2);
      this.companyName = new ShowMandatoryModel(_checkoutSettings.checkoutCompanyName);
      this.phone = new ShowMandatoryModel(_checkoutSettings.checkoutPhone);
      this.termsAndConditions = new ValueEnabledModel(_checkoutSettings.termsAndConditions);
      this.privacyPolicy = new ValueEnabledModel(_checkoutSettings.privacyPolicy);
      this.returnPolicy = new ValueEnabledModel(_checkoutSettings.refundCancellationPolicy);
      this.digitalPolicy = new ValueEnabledModel(_checkoutSettings.digitalItemPolicy);
      this.contactUs = new ValueEnabledTitleModel(_checkoutSettings.contactUs);
      this.customPolicy = new ValueEnabledTitleModel(_checkoutSettings.customPolicy);
      this.arePoliciesCheckedByDefault = !!_checkoutSettings.checkoutPolicyCheckbox?.checked;
      this.isThereAnyPolicyEnabled =
        this.termsAndConditions.enabled || this.privacyPolicy.enabled || this.returnPolicy.enabled;
      this.areGeneralPoliciesEnabled = !!_checkoutSettings.checkoutPolicyCheckbox?.show && this.isThereAnyPolicyEnabled;
      this.isDigitalPolicyEnabled = !!_checkoutSettings.checkoutPolicyCheckbox?.show && this.digitalPolicy.enabled;
      this.isSubscriptionCheckedByDefault = !!_checkoutSettings.checkoutSubscriptionCheckbox?.checked;
      this.isSubscriptionEnabled = !!_checkoutSettings.checkoutSubscriptionCheckbox?.show;
      this.isGiftCardEnabled = !!_checkoutSettings.checkoutGiftCardCheckbox?.show;
      this.isGiftCardSupported = !!_checkoutSettings.checkoutGiftCardCheckbox?.supported;
    }
  }
}
